const sharePriceInit = ev => {
  const baseURL =
    'https://wcsecure.weblink.com.au/Clients/Wellard/pricejson.aspx';

  /**
   * Get Json
   *
   * @param {any} url
   * @param {Function} callback
   */
  const getJSON = function(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = ev => {
      const status = xhr.status;
      if (status === 200) {
        callback(null, xhr.response);
      } else {
        callback(status, xhr.response);
      }
    };
    xhr.send();
  };

  /**
   * Show data in their respective elements
   *
   * @param {any} data
   */
  const processPriceData = data => {
    const priceEls = document.querySelectorAll('.shareprice__price');
    const timeEls = document.querySelectorAll('.shareprice__date time');

    const year = data.date.substring(0, 4);
    const month = data.date.substring(4, 6);
    const day = data.date.substring(6, 8);

    const hour = data.time.substring(0, 2);
    const minutes = data.time.substring(2, 4);
    const seconds = data.time.substring(4, 6);

    // show in all instances on the page
    [].slice.call(priceEls).map(el => {
      el.innerText = `$${parseFloat(data.price).toFixed(3)}`;
    });
    [].slice.call(timeEls).map(el => {
      el.setAttribute(
        'datetime',
        `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`
      );
      el.setAttribute('title', `${year}/${month}/${day} at ${hour}:${minutes}`);
      el.innerText = `${day}-${month}-${year} - ${hour}:${minutes}`;
    });
  };

  const sharepriceEls = document.querySelectorAll('.shareprice');
  [].slice.call(sharepriceEls).map(el => {
    el.classList.add('loading');
  });

  getJSON(baseURL, (err, data) => {
    if (err !== null) {
      console.error(err);
    } else {
      if (!data) {
        console.error('Bad data');
        return false;
      }
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      if (data.quote) {
        [].slice.call(sharepriceEls).map(el => {
          el.classList.remove('loading');
        });
        let _data = data.quote[0];
        // predicted incoming demo data
        processPriceData({
          price: _data.lastprice,
          date: _data.tradeddate,
          time: _data.tradedtime
        });
      }
    }
  });
};

document.addEventListener('DOMContentLoaded', sharePriceInit);
