// json https://jsonplaceholder.typicode.com/photos

const announcementInit = ev => {
  if (!document.getElementById('announcements__app')){
    return
  }
  const baseURL =
    'https://wcsecure.weblink.com.au/Clients/Wellard/headlinejson.aspx';

  /* VUE APP */

  const app = new Vue({
    el: '#announcements__app',
    data: {
      oldestYear: 0,
      latestYear: 0,
      currentYear: 0,
      totalPages: 0,
      currentPage: 0,
      items: []
    },

    created: function() {
      // check which is latest year

      this.fetchData(`${baseURL}`, data => {
        // save vars
        this.oldestYear = data.WebLinkHeadlineData.oldestYear;
        this.latestYear = data.WebLinkHeadlineData.latestYear;
        // set current year as the latest one
        this.currentYear = this.latestYear;
        // get current year, first page
        this.getYearContent(this.currentYear);
      });
    },

    methods: {
      fetchData: function(url, callback) {
        this.$http.get(url, callback);
      },
      getYearContent: function(year, page = 1) {

        document.getElementById('announcements__app').classList.add('loading');
        
        const url = `${baseURL}?year=${year}&pageNumber=${page}`;
        this.fetchData(url, data => {
          document.getElementById('announcements__app').classList.remove('loading');
          this.items = data.WebLinkHeadlineData.headlines;
          this.currentPage = data.WebLinkHeadlineData.pageNumber;
          this.totalPages = data.WebLinkHeadlineData.totalPages;
          this.currentYear = year;

          if (this.currentYear <= this.oldestYear) {
            document.querySelector('.announcements__heading .previous').classList.add('disable');
          } else {
            document.querySelector('.announcements__heading .previous').classList.remove('disable');
          }
          if (this.currentYear >= this.latestYear) {
            document.querySelector('.announcements__heading .next').classList.add('disable');
          } else {
            document.querySelector('.announcements__heading .next').classList.remove('disable');
          }

          if (this.currentPage <= 1) {
            [].slice.call(document.querySelectorAll('.announcements__pagination .previous')).map(el => {
              el.classList.add('disable');
            });


          } else {
            [].slice.call(document.querySelectorAll('.announcements__pagination .previous')).map(el => {
              el.classList.remove('disable');
            });
          }
          if (this.currentPage >= this.totalPages) {
            [].slice.call(document.querySelectorAll('.announcements__pagination .next')).map(el => {
              el.classList.add('disable');
            });
          } else {
            [].slice.call(document.querySelectorAll('.announcements__pagination .next')).map(el => {
              el.classList.remove('disable');
            });
          }
        });
      },
      printDate: function(datestring, timestring) {

        const year = datestring.substring(0, 4);
        const month = datestring.substring(4, 6);
        const day = datestring.substring(6, 8);

        const hour = timestring.substring(0, 2);
        const minutes = timestring.substring(2, 4);
        const seconds = timestring.substring(4, 6);

        const dateText = `${month}/${day}/${year} ${hour}:${minutes}:${seconds} (AWST)`;
        const publishedDate = new Date(dateText);
        const doubleDigits = value => {
          return ('0' + value).slice(-2);
        };

        let obj = {};
        if (isNaN(publishedDate.getDate())){
          obj = {
            datatime: ``,
            prettyShort: ``,
            prettyFull: ``
          }
        } else {
          obj = {
            datetime: `${publishedDate.getFullYear()}-${doubleDigits(
              publishedDate.getMonth() + 1
            )}-${doubleDigits(publishedDate.getDate())}T${doubleDigits(
              publishedDate.getHours()
            )}:${doubleDigits(publishedDate.getMinutes())}:${doubleDigits(
              publishedDate.getSeconds()
            )}`,
            prettyShort: `${publishedDate.getDate()} ${publishedDate.toLocaleString(
              'en-AU',
              {
                month: 'long'
              }
            )}, ${publishedDate.getFullYear()}`,
            prettyFull: `${publishedDate.getFullYear()}/${doubleDigits(
              publishedDate.getMonth() + 1
            )}/${doubleDigits(publishedDate.getDate())} at ${doubleDigits(
              publishedDate.getHours()
            )}:${doubleDigits(publishedDate.getMinutes())}`
          };
        }
        return obj;
      },
      prevyear: function(ev) {
        if (this.currentYear - 1 < this.oldestYear) {
        } else {
          this.getYearContent(this.currentYear - 1, 1);
        }
      },
      nextyear: function(ev) {
        if (this.currentYear + 1 > this.latestYear) {
        } else {
          this.getYearContent(this.currentYear + 1, 1);
        }
      },
      prevpage: function(ev) {
        if (this.currentPage <= 1) {
        } else {
          this.getYearContent(this.currentYear, this.currentPage - 1);
        }
      },
      nextpage: function(ev) {
        if (this.currentPage + 1 > this.totalPages) {
        } else {
          this.getYearContent(this.currentYear, this.currentPage + 1);
        }
      }
    }
  });
};
document.addEventListener('DOMContentLoaded', announcementInit);
