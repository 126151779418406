/**
 * Traverse through parent elements to find a specific classname
 *
 * @param {HTMLElement} el
 * @param {CSS} className
 * @returns
 */
const findParentBySelector = (el, className) => {
  while ((el = el.parentElement) && !el.classList.contains(className));
  return el;
};

/**
 * Main Javascript
 *
 * @param {Event} ev
 */
const mainJS = ev => {
  /**
   * Hero Carousel
   *
   * @param {CSS} selector
   */
  const initCarousel = (selector, options) => {
    const el = document.querySelector(selector);
    if (el) {
      jQuery(el).owlCarousel(options);
    }
  };

  /**
   * Initialise Accordion
   *
   * @param {CSS} selector
   */
  const initAccordions = selector => {
    const onAccordionHeadingClickHandler = ev => {
      ev.preventDefault();
      const buttonEl = ev.currentTarget;
      const itemEl = findParentBySelector(buttonEl, "accordion__item");
      const isActive = itemEl.classList.toggle("active");
      buttonEl.setAttribute("aria-expanded", isActive);
    };
    const accordionsEl = document.querySelectorAll(selector);
    if (accordionsEl) {
      [].slice.call(accordionsEl).map(accordionItemEl => {
        [].slice
          .call(accordionItemEl.querySelectorAll(".accordion__heading button"))
          .map(headingButtonEl => {
            headingButtonEl.addEventListener(
              "click",
              onAccordionHeadingClickHandler
            );
            headingButtonEl.addEventListener(
              "touchend",
              onAccordionHeadingClickHandler
            );
          });
      });
    }
  };

  initCarousel(".hero-carousel.owl-carousel", {
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    loop: true,
    items: 1,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    mouseDrag: false,
    touchDrag: false
  });
  initCarousel(".newsfeed__container.owl-carousel", {
    loop: true,
    responsiveClass: true,
    navText: [
      '<span><i class="fas fa-chevron-left"></i></span>',
      '<span><i class="fas fa-chevron-right"></i></span>'
    ],
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      }
    }
  });

  initAccordions(".accordion");

  // style grid not supported.
  if (document.body.style.grid === undefined){
    document.documentElement.classList.add('no-css-grid');
  }
  // object fit not supported
  if (document.body.style.objectFit === undefined){
    objectFitImages();
  }

  // Begin field info toggle
  var fieldSRN = document.querySelector('.shareholder-srn');
  var fieldHIN = document.querySelector('.shareholder-hin');

  const addFieldToggle = fieldEl => {
    const errorMessagePresent = document.querySelector('.gfield_error')

    if ( !! errorMessagePresent ) {
      return
    }
    
    const toggle = document.createElement('span')

    toggle.classList.add('toggle-icon')
    toggle.textContent = 'ℹ'

    fieldEl.querySelector('label').appendChild(toggle)
  }

  const infoToggleAction = fieldEl => {
    if ( ! fieldEl ) {
      return
    }

    addFieldToggle( fieldEl )

    fieldEl.querySelector('.toggle-icon').addEventListener('click', () =>
      fieldEl.querySelector('.gfield_description').classList.toggle('show')
    )
  }

  infoToggleAction(fieldSRN)
  infoToggleAction(fieldHIN)

};
document.addEventListener("DOMContentLoaded", mainJS);
